// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-container {
    position: relative;
    cursor: pointer;
    transition: transform 0.2s;
}

.image-container:hover {
    transform: scale(1.02);
}

.image-container.selected-delete .selected-overlay {
    background-color: rgba(255, 0, 0, 0.5);
    /* Red highlight */
}

.image-container.selected-top .selected-overlay {
    background-color: rgba(0, 0, 255, 0.5);
    /* Blue highlight */
}

.selected-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    font-weight: bold;
    color: white;
    transition: background-color 0.3s;
}`, "",{"version":3,"sources":["webpack://./src/components/styles/CheckPlaceNeedsAttention.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;IACf,0BAA0B;AAC9B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,sCAAsC;IACtC,kBAAkB;AACtB;;AAEA;IACI,sCAAsC;IACtC,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,iCAAiC;AACrC","sourcesContent":[".image-container {\n    position: relative;\n    cursor: pointer;\n    transition: transform 0.2s;\n}\n\n.image-container:hover {\n    transform: scale(1.02);\n}\n\n.image-container.selected-delete .selected-overlay {\n    background-color: rgba(255, 0, 0, 0.5);\n    /* Red highlight */\n}\n\n.image-container.selected-top .selected-overlay {\n    background-color: rgba(0, 0, 255, 0.5);\n    /* Blue highlight */\n}\n\n.selected-overlay {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    font-size: 2rem;\n    font-weight: bold;\n    color: white;\n    transition: background-color 0.3s;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
