// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.place-list {
    width: 100%;
    /* Use full width to avoid uneven spacing */
    padding: 0;
    /* Remove default padding for better alignment */
    list-style-type: none;
    /* Remove bullets */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.place-item {
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 15px;
    transition: transform 0.2s, background-color 0.2s;
    text-align: center;
    width: 90%;
    /* Ensure items are centered and uniformly sized */
    max-width: 600px;
    /* Set a maximum width for larger screens */
}

.place-item:hover {
    transform: scale(1.02);
    background-color: #e9ecef;
}

.place-link {
    font-size: 1.2rem;
    font-weight: 500;
    color: #343a40;
    text-decoration: none;
    transition: color 0.2s;
    display: block;
    /* Make the entire item clickable */
    width: 100%;
}

.place-link:hover {
    color: #212529;
    text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/styles/PlacesNeedingAttention.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,2CAA2C;IAC3C,UAAU;IACV,gDAAgD;IAChD,qBAAqB;IACrB,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,iDAAiD;IACjD,kBAAkB;IAClB,UAAU;IACV,kDAAkD;IAClD,gBAAgB;IAChB,2CAA2C;AAC/C;;AAEA;IACI,sBAAsB;IACtB,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;IACd,qBAAqB;IACrB,sBAAsB;IACtB,cAAc;IACd,mCAAmC;IACnC,WAAW;AACf;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB","sourcesContent":[".place-list {\n    width: 100%;\n    /* Use full width to avoid uneven spacing */\n    padding: 0;\n    /* Remove default padding for better alignment */\n    list-style-type: none;\n    /* Remove bullets */\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.place-item {\n    background-color: #f8f9fa;\n    border: 1px solid #dee2e6;\n    border-radius: 5px;\n    margin-bottom: 10px;\n    padding: 15px;\n    transition: transform 0.2s, background-color 0.2s;\n    text-align: center;\n    width: 90%;\n    /* Ensure items are centered and uniformly sized */\n    max-width: 600px;\n    /* Set a maximum width for larger screens */\n}\n\n.place-item:hover {\n    transform: scale(1.02);\n    background-color: #e9ecef;\n}\n\n.place-link {\n    font-size: 1.2rem;\n    font-weight: 500;\n    color: #343a40;\n    text-decoration: none;\n    transition: color 0.2s;\n    display: block;\n    /* Make the entire item clickable */\n    width: 100%;\n}\n\n.place-link:hover {\n    color: #212529;\n    text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
